<template>
  <section class="page">
    <div>
      <div class="response">
        <div class="response__title">
          Вы успешно зарегистрировались в общей очереди
        </div>

        <div v-if="queuePosition" class="response__place">
          <span class="response__place-title">Ваше место в очереди:</span>
          <span>&nbsp;{{ queuePosition }}</span>
        </div>

        <div class="response__subtitle">
          <div class="response__subtitle-info">
            Ожидайте смс с приглашением на выгрузку, она придет на номер:
          </div>
          <div class="response__subtitle-phone">{{ $route.params.phone }}</div>
        </div>

        <div class="response__text">
          Напоминаем, что авто, зарегистрировавшиеся в общей очереди, будут
          вызваны на выгрузку при отсутствии на парковке авто, приехавших по
          забронированным таймслотам.
        </div>
      </div>
      <div class="flex">
        <MainButton
          title="Показать меня в очереди"
          type="primary"
          size="big"
          width="full"
          class="confirm-button"
          @button-clicked="
            $router.push({
              name: 'terminal-currentQueue',
              params: { plate_truck: $route.params.plate_truck },
            })
          "
        />
        <MainButton
          title="Понятно"
          type="secondary"
          size="big"
          width="full"
          class="confirm-button"
          @button-clicked="$router.push({ name: 'terminal-carNumber' })"
        />
      </div>
    </div>

    <TechSupport />
  </section>
</template>

<script>
import { GET_PROFILE_FROM_STATE } from '@/views/profile/store/actions'
import { mapGetters } from 'vuex'
import Api from '@/api'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import finallyPagesMixin from '@/views/terminalUI/mixins/finallyPagesMixin'
export default {
  name: 'FinallyHandResponse',
  components: {
    MainButton,
    TechSupport,
  },
  mixins: [finallyPagesMixin],
  data() {
    return {
      isLoading: false,
      queuePosition: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: GET_PROFILE_FROM_STATE,
    }),
    terminalIds() {
      return this.currentUser?.unloads?.map(item => item.id)
    },
  },
  async created() {
    this.isLoading = true
    const params = {
      unload_id: this.terminalIds,
      plate_truck: this.$route.params.plate_truck,
    }

    try {
      const { data } = await Api.terminal.getTerminalQueuePosition(params)

      this.queuePosition = data.position
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 70px 9% 20px

  .response
    background: $color-white
    border-radius: 40px
    padding: 40px
    margin-bottom: 60px
    color: $mainBlack
    &__title
      display: block
      width: 790px
      margin-bottom: 32px
      font-family: $fontPrimary
      font-size: 40px
      font-weight: 500
      line-height: 48px

    &__place
      margin-bottom: 20px
      font-family: $fontPrimary
      font-size: 24px
      font-weight: 500
      line-height: 30px
      &-title
        color: $greyText

    &__subtitle, &__text
      width: 790px
      font-family: $fontPrimary
      font-size: 24px
      font-weight: 500
      line-height: 30px
    &__subtitle
      margin-bottom: 32px
      &-info
        font-family: $fontPrimary
      &-phone
        margin-top: 12px
        font-family: $fontPrimary
        color: $special

  .flex
    display: flex
    gap: 32px
  .confirm-button
    margin: 0 auto
</style>
