var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c("div", [
        _c("div", { staticClass: "response" }, [
          _c("div", { staticClass: "response__title" }, [
            _vm._v(" Вы успешно зарегистрировались в общей очереди "),
          ]),
          _vm.queuePosition
            ? _c("div", { staticClass: "response__place" }, [
                _c("span", { staticClass: "response__place-title" }, [
                  _vm._v("Ваше место в очереди:"),
                ]),
                _c("span", [_vm._v(" " + _vm._s(_vm.queuePosition))]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "response__subtitle" }, [
            _c("div", { staticClass: "response__subtitle-info" }, [
              _vm._v(
                " Ожидайте смс с приглашением на выгрузку, она придет на номер: "
              ),
            ]),
            _c("div", { staticClass: "response__subtitle-phone" }, [
              _vm._v(_vm._s(_vm.$route.params.phone)),
            ]),
          ]),
          _c("div", { staticClass: "response__text" }, [
            _vm._v(
              " Напоминаем, что авто, зарегистрировавшиеся в общей очереди, будут вызваны на выгрузку при отсутствии на парковке авто, приехавших по забронированным таймслотам. "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("MainButton", {
              staticClass: "confirm-button",
              attrs: {
                title: "Показать меня в очереди",
                type: "primary",
                size: "big",
                width: "full",
              },
              on: {
                "button-clicked": function ($event) {
                  return _vm.$router.push({
                    name: "terminal-currentQueue",
                    params: { plate_truck: _vm.$route.params.plate_truck },
                  })
                },
              },
            }),
            _c("MainButton", {
              staticClass: "confirm-button",
              attrs: {
                title: "Понятно",
                type: "secondary",
                size: "big",
                width: "full",
              },
              on: {
                "button-clicked": function ($event) {
                  return _vm.$router.push({ name: "terminal-carNumber" })
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }